import moment from 'moment';
import watch from 'redux-watch';
import momentTimezone from 'moment-timezone';
import { executeDeepLink, authentication } from '@microsoft/teams-js';

import { store } from 'index';
import communifyApi from 'Configs/Api';
import { times, timesTo24 } from 'Configs/SelectData/SelectData';
import { TEAMS_DEEP_LINK_URL, TEAMS_APP_ID } from 'Configs/Constants/constants';
import { setSnackbarMessage } from 'Components/Snackbar/snackbarSlice';
import { setDiscardChanged, setToken } from 'Pages/Dashboard/DashboardSlice';
import { getMeetings } from 'Pages/Dashboard/UpcomingMeetings/UpcomingMeetingUtils';

export const navigateInTeams = entityID => {
  // there is also a callBack onComplete?: (status: boolean, reason?: string) => void) << by teams documentation, but currently not working
  // link: https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams?view=msteams-client-js-latest#executedeeplink-string---status--boolean--reason---string-----void-
  executeDeepLink(`${TEAMS_DEEP_LINK_URL}/${TEAMS_APP_ID}/${entityID}`);
};

export const goBack = history => history.goBack();

export const detectMob = () => window.innerWidth <= 1024;

export const switchTouchColor = (e, color) => {
  e.persist();

  const el = e.currentTarget;
  el.style.backgroundColor = color;
};

export const request = async ({ method = 'get', url, data = null, config = null, history }) => {
  let response = null;
  let error = '';
  let isLoading = true;
  let message = null;

  try {
    const res = await communifyApi[method](url, JSON.parse(config), JSON.parse(data));

    if (res?.data?.messageKey === 'TeamsAppNotAccess') {
      history.push('/not-found');
    }

    if (res.data.hasError === false) {
      response = res.data.data;
      message = res.data.message;
    } else throw new Error(res.data.message);
  } catch (err) {
    error = err.message;
  } finally {
    isLoading = false;
  }

  return { response, error, isLoading, message };
};

export const getNextHalfTime = (format = 'HH:mm:00', customTime = null, toUtc) => {
  const momentNow = customTime ? moment(customTime, [format]) : moment();

  const minute = customTime
    ? parseInt(customTime.substring(2, 5).trim().replace(':', ''))
    : momentNow.minute();

  const reminder = 15 - (minute % 15);

  return toUtc
    ? momentNow.add(reminder, 'minutes').utc().format(format)
    : momentNow.add(reminder, 'minutes').format(format);
};

export const cancellMeeting = async (
  meetingId,
  startDate,
  seriesId = null,
  route = 'GetUpComingMeetings',
) => {
  const reuqestData = seriesId ? { seriesMasterId: seriesId, startDate } : { meetingId, startDate };
  const reuqestPath = seriesId ? '/DashBoard/DeleteSeries' : '/DashBoard/DeleteMeeting';

  const { response, error } = await request({
    url: reuqestPath,
    method: 'post',
    data: JSON.stringify({
      params: reuqestData,
    }),
  });

  if (response) {
    await getMeetings(route, 20, startDate);
  }

  return { response, error };
};

export const timeToUTC = (date, time) => moment(`${date} ${time}`).utc().format('HH:mm:ss');

export const timeTo24 = (time, format = 'HH:mm:ss') => moment(time, ['h:mm A']).format(format);

export const timeToBrowserTimezone = time => {
  const timezone = momentTimezone;

  return timezone.utc(time).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('h:mm A');
};

export const generateHours = () => {
  const hours = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      hours.push({
        value: moment({ hour, minute }).format('HH:mm:ss'),
        label: moment({ hour, minute }).format('hh:mm A'),
      });
    }
  }

  return hours;
};

// Show the consent pop-up
export const requestConsent = res => {
  return new Promise((resolve, reject) => {
    authentication.authenticate({
      url: res,
      width: 600,
      height: 535,
      successCallback: function (result) {
        resolve(result);
      },
      failureCallback: function (reason) {
        if (typeof reason === 'string') {
          reject(reason);
        }
      },
    });
  });
};

const handleRedirectionAfterAuth = (history, dispatch, path) => {
  if (localStorage.tokenData) {
    if (JSON.parse(localStorage.tokenData).hasTeamsAcess === false) {
      if (history) history.push('/not-found');
      else window.location.href = '/not-found';
    } else {
      if (
        window.location.pathname.includes('/logout') ||
        window.location.pathname.includes('/signin')
      ) {
        if (history) {
          history.push(path);
        } else {
          window.location.reload();
        }
      } else {
        if (history) {
          history.go(path);
        } else window.location.reload();
      }
    }
  } else {
    if (dispatch) dispatch(setSnackbarMessage('Please log in to continue'));
  }
};

export const redirectToAuth = async (dispatch, history, prevPath) => {
  localStorage.removeItem('tokenData');

  const { response: res } = await request({
    url: '/UserAuthenticate/GetAuthLink',
    data: JSON.stringify({
      params: {
        redirectUrl: `${process.env.REACT_APP_BASE_APP_URL}/dashboard`,
      },
    }),
  });

  if (res) {
    const checkBaseUrl = process.env.REACT_APP_BASE_APP_URL.includes('localhost')
      ? 'https://teamsapp.dev.communifyapp.com'
      : `${process.env.REACT_APP_BASE_APP_URL}`;

    const resLocal = res.replace(checkBaseUrl, `${process.env.REACT_APP_BASE_APP_URL}/dashboard`);
    localStorage.setItem('authUrl', resLocal);
    const isLogout = new URLSearchParams(window.location.search).get('islogout');
    const path = isLogout ? '/logout' : prevPath || '/dashboard';

    requestConsent(`${checkBaseUrl}/start-auth`)
      .then(result => {
        if (dispatch) dispatch(setToken(result));
        handleRedirectionAfterAuth(history, dispatch, path);
      })
      .catch(() => {
        handleRedirectionAfterAuth(history, dispatch, path);
      });

    localStorage.setItem('prevState', resLocal.split('&')[5].replace('state=', ''));
  }
};

export const refreshAccessToken = async () => {
  const { response: res } = await request({
    url: '/UserAuthenticate/RefreshToken', // TODO; Make dashboard resuable
    method: 'post',
    data: JSON.stringify({
      params: {
        refreshToken: JSON.parse(localStorage.tokenData).refreshToken,
      },
    }),
  });

  if (res) {
    localStorage.setItem('tokenData', JSON.stringify(res));
    return res?.accessToken;
  } else {
    window.location.href = '/signin';
  }
};

export const loginUser = async (state, code) => {
  if (state === localStorage.prevState) {
    const { response: res, error } = await request({
      url: '/UserAuthenticate/Login',
      method: 'post',
      data: JSON.stringify({
        params: {
          code,
          redirectUrl: `${process.env.REACT_APP_BASE_APP_URL}/dashboard`,
        },
      }),
    });

    return { res, error };
  }
};

export const logoutUser = async (history, setState) => {
  setState(false);

  localStorage.removeItem('tokenData');

  history.push({
    pathname: '/logout',
    state: {
      prevPath: history.location.pathname.includes('dashboard') ? '/dashboard' : '/my-meetings',
    },
  });
};

export const selectScrollIntoView = time => {
  setTimeout(() => {
    document
      .querySelectorAll('[class*=teams-select__menu-list] [class*=teams-select__option]')
      .forEach(elem => {
        if (elem.textContent === getNextHalfTime('h:mm A', time.label)) {
          document.querySelector('[class*=teams-select__menu-list]').scrollTop = elem.offsetTop;
        }
      });
  }, 100);
};

export const onChangeSelectInput = e => {
  const timeCheck = e.split(':');
  const isTimeCustom = times.find(time => time.label.startsWith(e.toUpperCase()));

  const isTimeCustomIndex = times.findIndex(time =>
    time.label.startsWith(getNextHalfTime('h:mm A', e)),
  );

  const customIndexCheck = isTimeCustomIndex !== -1 ? isTimeCustomIndex : times.length - 1;

  if (timeCheck.length === 2) {
    if (timeCheck[0][0] !== '0' && timeCheck[1].length >= 2 && timeCheck[1].length <= 5) {
      if (!isTimeCustom) {
        const hourCheck24 = parseInt(timeCheck[0]) > 12 ? timeCheck[0] : timesTo24[timeCheck[0]];

        if (
          timeCheck[1].toUpperCase().endsWith('PM') ||
          timeCheck[1].toUpperCase().endsWith('AM')
        ) {
          times.splice(customIndexCheck, 0, {
            label: `${timeCheck[0]}:${timeCheck[1].toUpperCase()}`,
            value: `${hourCheck24}:${timeCheck[1].replace('PM', '').replace('AM', '').trim()}:00`,
          });
        } else if (timeCheck[1].length === 2 && !isNaN(parseInt(timeCheck[1]))) {
          times.splice(customIndexCheck, 0, {
            label: `${timeCheck[0]}:${timeCheck[1].toUpperCase()} ${moment().format('A')}`,
            value: `${hourCheck24}:${timeCheck[1].replace('PM', '').replace('AM', '').trim()}:00`,
          });
        }
      }
    }
  }
};

export const useDiscardCheck = () => {
  let w = watch(store.getState, 'newMeeting');
  const initalState = JSON.stringify(store.getState().newMeeting);

  store.subscribe(
    w((newVal, oldVal, objectPath) => {
      const upcomingVal = { ...newVal };
      delete upcomingVal.timeZone;

      if (initalState !== JSON.stringify(upcomingVal)) store.dispatch(setDiscardChanged(true));
      else store.dispatch(setDiscardChanged(false));
    }),
  );
};

export const filterConfig = (option, searchText, format) => {
  const term =
    searchText[0] === '0' ? searchText.substring(1).toUpperCase() : searchText.toUpperCase();

  return term.length === 0
    ? option.label.startsWith(term)
    : option.label.startsWith(term) && option.label.includes(format);
};

export const iOS = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform,
  ) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
